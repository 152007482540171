// Material Dashboard 2 PRO React base styles
import colors from "assets/theme-dark/base/colors";

// Material Dashboard 2 PRO React helper functions
import rgba from "assets/theme-dark/functions/rgba";

const { primary, white, dark, gradients } = colors;

const flatpickr = {
  ".flatpickr-calendar": {
    fontSize: `12px !important`,
    borderRadius: `0px !important`,
  },
  ".flatpickr-day:hover, .flatpickr-day:focus, .flatpickr-day.nextMonthDay:hover, .flatpickr-day.nextMonthDay:focus":
    {
      background: rgba(primary.main, 0.28),
      color: `${white.main} !important`,
      border: "none",
    },

  ".flatpickr-day.today": {
    background: primary.main,
    color: `${white.main} !important`,
    border: "none",

    "&:hover, &:focus": {
      background: `${primary.focus} !important`,
    },
  },
  ".flatpickr-current-month .numInputWrapper span.arrowUp:after": {
    borderBottomColor: `${white.main} !important`,
  },
  ".flatpickr-current-month .numInputWrapper span.arrowDown:after": {
    borderTopColor: `${white.main} !important`,
  },
  ".numInputWrapper span": {
    opacity: `1 !important`,
  },
  ".flatpickr-day.selected, .flatpickr-day.selected:hover, .flatpickr-day.nextMonthDay.selected, .flatpickr-day.nextMonthDay.selected:hover, .flatpickr-day.nextMonthDay.selected:focus":
    {
      background: `${gradients.primary.state} !important`,
      color: `${white.main} !important`,
      border: "none",
    },
  ".flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month": {
    color: `${white.main} !important`,
    fill: `${white.main} !important`,
  },
  ".flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg":
    {
      color: `${white.main} !important`,
      fill: `${white.main} !important`,
    },

  ".flatpickr-innerContainer": {
    background: `${dark.main} !important`,
  },
  ".flatpickr-weekday": {
    color: `${white.main} !important`,
  },
  ".flatpickr-day": {
    color: `${white.main} !important`,
  },
  ".flatpickr-day:hover": {
    background: `${primary.main} !important`,
    color: `${white.main} !important`,
  },
  ".flatpickr-day.nextMonthDay, .flatpickr-day.prevMonthDay, .flatpickr-day.flatpickr-disabled": {
    color: `${white.main}40 !important`,
  },
  ".flatpickr-day.inRange": {
    color: `${dark.main} !important`,
  },
  ".flatpickr-weekdays, .flatpickr-weekday, .flatpickr-month, .flatpickr-monthDropdown-months, .flatpickr-monthDropdown-month":
    {
      background: `${primary.main} !important`,
      color: `${white.main} !important`,
    },
};

export default flatpickr;
