import React from "react";
import PropTypes from "prop-types";

import { useMaterialUIController } from "context";

import "./styles.css";
export default function CustomLoading({ progress = "", width = "150px", height = "150px" }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <div className="loading-overlay">
      <div
        className={`${darkMode ? "progress-text-color-dark" : "progress-text-color"} progress-text`}
      >
        {progress}
      </div>
      <div
        className={`${darkMode ? "spinner-border-color-dark" : "spinner-border-color"} spinner`}
        style={{
          width,
          height,
        }}
      ></div>
    </div>
  );
}

CustomLoading.propTypes = {
  progress: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
