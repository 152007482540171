import React from "react";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Custom styles for the SidenavItem
import { item, itemContent, itemArrow } from "examples/Sidenav/styles/sidenavItem";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

function SidenavItem({
  color = "primary",
  active = false,
  nested = false,
  children = false,
  open = false,
  name,
  icon,
  ...rest
}) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

  const iconElement =
    typeof icon === "string" ? (
      <Icon
        sx={(theme) => {
          let c = theme.palette.dark.main;
          if (!transparentSidenav && !whiteSidenav) c = theme.palette.white.main;
          else if (active == true && (transparentSidenav || whiteSidenav))
            c = theme.palette.white.main;
          else c = theme.palette.dark.main;
          return {
            color: c,
          };
        }}
        fontSize="medium"
      >
        {icon}
      </Icon>
    ) : icon ? (
      icon
    ) : null;
  return (
    <>
      <ListItem
        {...rest}
        component="li"
        sx={(theme) => item(theme, { active, color, transparentSidenav, whiteSidenav, darkMode })}
      >
        {iconElement}
        <MDBox
          sx={(theme) =>
            itemContent(theme, {
              icon: iconElement,
              active,
              miniSidenav,
              name,
              open,
              nested,
              transparentSidenav,
              whiteSidenav,
              darkMode,
            })
          }
        >
          <ListItemText primary={name} />
          {children && (
            <Icon
              component="i"
              sx={(theme) =>
                itemArrow(theme, { open, miniSidenav, transparentSidenav, whiteSidenav, darkMode })
              }
            >
              expand_less
            </Icon>
          )}
        </MDBox>
      </ListItem>
      {children && (
        <Collapse component="li" in={open} timeout="auto" unmountOnExit {...rest}>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
  color: PropTypes.oneOf([
    "inherit",
    "white",
    "primary",
    "primaryLight",
    "secondary",
    "purpleShade",
    "info",
    "success",
    "successDark",
    "warning",
    "error",
    "errorDark",
    "light",
    "dark",
    "text",
    "black",
  ]),
  name: PropTypes.string.isRequired,
  active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  nested: PropTypes.bool,
  children: PropTypes.node,
  open: PropTypes.bool,
  icon: PropTypes.node,
};

export default SidenavItem;
